import { logMessage, LOG_LEVELS } from './logging.js';

// Import Firebase modules
import { do_logout, setupAuthentication } from './auth.js';


function loadHeader() {
  return new Promise((resolve, reject) => {
      fetch('header.html')
          .then(response => response.text())
          .then(data => {
              document.getElementById('header-placeholder').innerHTML = data;
              resolve();
          })
          .catch(error => {
              console.error('Error loading header:', error);
              reject(error);
          });
  });
}

function loadFooter() {
  return new Promise((resolve, reject) => {
      fetch('footer.html')
          .then(response => response.text())
          .then(data => {
              document.getElementById('footer-placeholder').innerHTML = data;
              resolve();
          })
          .catch(error => {
              console.error('Error loading footer:', error);
              reject(error);
          });
  });
}

// Load header, then set up the menu functionality
loadHeader().then(() => {
  try {
      setupMenu();
      setupAuthentication(handleAuthenticatedUser, handleUnauthenticatedUser);
      logMessage(LOG_LEVELS.INFO, 'Menu setup complete.');
  } catch (error) {
      logMessage(LOG_LEVELS.ERROR, 'Error setting up menu', { error: error.message });
  }
});

loadFooter();

function setupMenu() {
  const menu = document.getElementById("menuButton");
  menu.addEventListener('click', function() {
      toggleMenu();
  });
}

function toggleMenu() {
  const navbar = document.querySelector('.navbar');
  navbar.style.display = navbar.style.display === "block" ? "none" : "block";
}

function handleAuthenticatedUser(user, token) {

  const loginButton = document.getElementById('login-btn');
  const logoutButton = document.getElementById('logout-btn');
  const accountLink = document.getElementById('account-link');

  // User is signed in
  if (loginButton) loginButton.style.display = 'none';
  if (logoutButton) {
    logoutButton.style.display = 'block';
    logoutButton.addEventListener('click', logoutUser);
  }
  if (accountLink) accountLink.style.display = 'block';
}

function handleUnauthenticatedUser(user, token) {
  const loginButton = document.getElementById('login-btn');
  const logoutButton = document.getElementById('logout-btn');
  const accountLink = document.getElementById('account-link');
  const homeLink = document.getElementById('home-link');
  const integrationsLink = document.getElementById('integrations-link');

  // User is signed out
  if (loginButton) loginButton.style.display = 'block';
  if (logoutButton) {
    logoutButton.style.display = 'none';
    logoutButton.removeEventListener('click', logoutUser);
  }
  if (accountLink) accountLink.style.display = 'none';
  if (homeLink) homeLink.style.display = 'none';
  if (integrationsLink) integrationsLink.style.display = 'none';
  
}

function logoutUser(event) {
  event.preventDefault(); // Prevent default link behavior
  do_logout();
}