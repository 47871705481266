import { LOG_LEVELS, logMessage } from "../logging";
import {produce} from 'immer';


class StateManager {
  constructor() {
    if (!StateManager.instance) {
      this.state = {
        isMobileDevice: false,
        frontImageSelected: false,
        reinitialize: false,
        currentCardFrontCount: null,
        currentCardBackCount: null,
        dropzoneFiles: 0,
        stopProcessing: false,
        newCardArrived: false,
        firstCard: true,
        frontStreamDone: false,
        backStreamDone: false,
        currentCardFront: null,
        currentCardBack: null,
        clickActionTaken: false,
        alertOnMismatch: false,
        popupOnDisplay: false,
        newUser: true,
        currentFrontRequestId: 0,
        currentBackRequestId: 0,
        currentFontImage: null,
        currentBackImage: null,
        currentFrontCoords: null,
        currentBackCoords: null,
        frontRotation: 0,
        backRotation: 0,
        userModified: false,
        inRescan: false,
        frontInErrorState: false,
        backInErrorState: false,
        multipleErrorState: false,
        multipleErrorUnprocessed: 0,
        subscription_level: null,
        max_cards: 3,
        checklistMatch: false,
        sets_and_parallels: null,
        phaseOneData: null,
        inPhase2: false,
        cardState: {
          cardFronts: {},
          cardBacks: {},
        },
        userObj: null
      };
      this.subscribers = [];
      StateManager.instance = this;
    }
    return StateManager.instance;
  }


updateState(newState) {
    this.updateStateWithoutNotify(newState);
    this.notifySubscribers();
}

updateStateWithoutNotify(newState) {
  this.oldState = { ...this.state }; // Store the old state

  const nextState = produce(this.state, draftState => {
      Object.assign(draftState, newState);
  });
  this.state = nextState;
}

notifySubscribers() {
  this.subscribers.forEach(callback => {
      if (typeof callback === 'function') {
          callback(this.state, this.oldState);
      } else {
          console.error('Invalid subscriber:', callback);
      }
  });
}

  getState() {
    return this.state;
  }

  addCardFronts(requestId, frontImage) {
    logMessage(LOG_LEVELS.DEBUG, "Adding card front: ", { requestId, frontImage });

    this.state = produce(this.state, draftState => {
      if (!draftState.cardState.cardFronts[requestId]) {
        draftState.cardState.cardFronts[requestId] = [];
      }
      draftState.cardState.cardFronts[requestId].push(frontImage);
    });
  }

  addCardBacks(requestId, backImage) {
    logMessage(LOG_LEVELS.DEBUG, "Adding card back: ", { requestId, backImage });

    this.state = produce(this.state, draftState => {
      if (!draftState.cardState.cardBacks[requestId]) {
        draftState.cardState.cardBacks[requestId] = [];
      }
      draftState.cardState.cardBacks[requestId].push(backImage);
    });
  }

  getNextCard(frontRequestId, backRequestId) {
    logMessage(LOG_LEVELS.DEBUG, "Dequeueing card.", { frontRequestId, backRequestId });

    if (!this.hasCard(frontRequestId, backRequestId)) {
      logMessage(LOG_LEVELS.ERROR, "No cards for the provided request IDs!");
      return { currentCardFront: null, currentCardBack: null };
    }

    const { cardFronts, cardBacks } = this.state.cardState;
    const currentCardFront = cardFronts[frontRequestId][0];
    const currentCardBack = cardBacks[backRequestId][0];

    this.setCurrentCards(currentCardFront, currentCardBack);
    this.removeFirstCardData(frontRequestId, backRequestId);

    return { currentCardFront, currentCardBack };
  }

  hasCard(frontRequestId, backRequestId) {
    const [frontQueueLength, backQueueLength] = this.queueLength(frontRequestId, backRequestId);

    logMessage(LOG_LEVELS.DEBUG, "Checking card existence", {
      frontRequestId,
      backRequestId,
      frontQueueLength,
      backQueueLength,
    });

    return frontQueueLength > 0 && backQueueLength > 0;
  }


  queueLength(frontRequestId, backRequestId) {
    const { cardFronts, cardBacks } = this.state.cardState;

    const frontQueue = cardFronts[frontRequestId] || [];
    const backQueue = cardBacks[backRequestId] || [];

    const frontQueueLength = frontQueue.length;
    const backQueueLength = backQueue.length;

    logMessage(LOG_LEVELS.DEBUG, "Queue lengths for request IDs", {
      frontRequestId,
      frontQueueLength,
      backRequestId,
      backQueueLength,
    });

    return [frontQueueLength, backQueueLength];
  }

  // Helper method to update the current cards without notifying subscribers
  setCurrentCards(frontCard, backCard) {
      this.updateStateWithoutNotify({ currentCardFront: frontCard, currentCardBack: backCard });
  }

   // Helper method to remove the first element from the lists associated with the request IDs
   removeFirstCardData(frontRequestId, backRequestId) {
    this.state = produce(this.state, draftState => {
      draftState.cardState.cardFronts[frontRequestId] = draftState.cardState.cardFronts[frontRequestId].slice(1);
      draftState.cardState.cardBacks[backRequestId] = draftState.cardState.cardBacks[backRequestId].slice(1);
    });
  }


  subscribe(callback) {
    this.subscribers.push(callback);
    return () => {
      const index = this.subscribers.indexOf(callback);
      if (index > -1) {
        this.subscribers.splice(index, 1);
      }
    };
  }

  // Implement other methods here, based on your existing functions
}

const instance = new StateManager();

export default instance;
