const config = {
    detectionUrl: process.env.DETECTION_URL,
    ebayAuthorizeUrl: process.env.EBAY_AUTHORIZE_URL,
    ebayClientId: process.env.EBAY_CLIENT_ID,
    ebayRuName: process.env.EBAY_RUNAME,
    stripe_public_key: process.env.STRIPE_PUBLIC_KEY,
    stripe_monthly_price: process.env.STRIPE_MONTHLY_PRICE,
    stripe_annual_price: process.env.STRIPE_ANNUAL_PRICE

    // Add other development-specific configuration here
};

export const CLIENT_ID = `${config.ebayClientId}`
export const RuName = `${config.ebayRuName}`
export default config;
